/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/3
* @description
*   Create.vue of WeTrade
*/
<template>
  <PageWithHeader class="create">
    <template #title>
      <!-- <t :path="title.label" class="f-lg" :class="`c-${title.themeClass}`"> -->
      <t :path="title.label" class="f-lg">
        {{ title.labelDefault }}
      </t>
    </template>
    <template #header>
      <DemoAccountTip />
    </template>
    <div class="create-body">
      <div class="create-content">
        <div class="py-4">
          <QuoteBar :symbol="symbol" class="px-12" />
        </div>

        <p class="c-tip f-sm px-16 d-f" v-if="sepcialType===ESpecialType.DOUBLE">
          <Icon name="help" class="mr-4" sm />
          <t path="placeorder_51" :args="{code: contractCode}" as="p" style="font-style: oblique;">
            {{contractCode}} will automatically close the position by the end of the day.
          </t>
        </p>

        <div class="px-16">
          <t custom #="{ td, t }">
            <Tab
              :tabs="[
                t('placeorder_2') || 'Market Order',
                t('placeorder_3') || 'Limit Order',
              ]"
              @switch="isCommission = !isCommission"
            />
          </t>
          <div class="mb-16">
            <TheTradeProducts class="mt-24" />
            <template v-if="isCommission">
              <!-- <Line /> -->
              <TheTradeTargetPrice class="my-32" />
            </template>
            <!-- <Line class="my-16" /> -->
            <TheTradePaymentMethods class="mt-32" />
            <Line class="my-16" />
            <TheTradeLimits />
            <Line class="my-16" />
            <TheStopLoss :type="type" />
            <Line class="my-16" />
            <TheTakeProfit :type="type" />
            <Line class="my-16" />
            <TheTradeCost class="pb-32" />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="d-f f-bold f-lg create-bottom">
        <Button class="flex-1" @click="$router.back()">
          <t path="placeorder_18" #="{ td }" custom>{{ td || "Cancel" }}</t>
        </Button>
        <div class="flex-2">
          <TheCommissionCreateButton
            v-if="isCommission"
            :class="[themeClass]"
          />
          <ThePositionCreateButton v-else :class="[themeClass]" />
        </div>
      </div>
    </template>
  </PageWithHeader>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import Line from '@/components/Line.vue'
import QuoteBar from '@/components/QuoteBar.vue'
import Tab from '@/components/Tab.vue'
import { useQuote } from '@/modules/market/market'
import TheCommissionCreateButton from '@/modules/trade/components/TheCommissionCreateButton.vue'
import TheTradeCost from '@/modules/trade/components/TheTradeCost.vue'
import TheStopLoss from '@/modules/trade/components/TheStopLoss.vue'
import TheTakeProfit from '@/modules/trade/components/TheTakeProfit.vue'
import TheTradeLimits from '@/modules/trade/components/TheTradeLimits.vue'
import TheTradePaymentMethods from '@/modules/trade/components/TheTradePaymentMethods.vue'
import ThePositionCreateButton from '@/modules/trade/components/ThePositionCreateButton.vue'
import TheTradeProducts from '@/modules/trade/components/TheTradeProducts.vue'
import TheTradeTargetPrice from '@/modules/trade/components/TheTradeTargetPrice.vue'
import TradeModel from '@/modules/trade/TradeModel'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { TradeDirection } from '@/types'
import DemoAccountTip from '@/views/DemoAccountTip.vue'
import {
  defineComponent,
  provide,
  shallowReadonly,
  shallowRef,
  watchEffect,
} from 'vue'
import { useRoute } from 'vue-router'
import Icon from '@/components/Icon.vue'
import { checkIsSpecial, ESpecialType } from '@/modules/market/market.api'

export default defineComponent({
  name: 'Create',
  components: {
    QuoteBar,
    DemoAccountTip,
    Button,
    TheCommissionCreateButton,
    ThePositionCreateButton,
    TheTradeLimits,
    Line,
    TheTradeCost,
    TheStopLoss,
    TheTakeProfit,
    TheTradePaymentMethods,
    TheTradeTargetPrice,
    TheTradeProducts,
    Tab,
    PageWithHeader,
    Icon,
  },
  setup () {
    const isCommission = shallowRef(false)
    const route = useRoute()
    const type = Number(route.params.type) as TradeDirection
    const model = new TradeModel(type)
    const symbol = useQuote(route.params.code as string)
    const contractCode = route.params.code as string
    const sepcialType = shallowRef(ESpecialType.NORMAL)

    checkIsSpecial({
      contract: route.params.code as string,
    }).then(res => {
      sepcialType.value = res.specialIdentification
    })

    watchEffect(() => {
      if (symbol.value) {
        model.setOffset(symbol.value.sell)
      }
    })

    const dynamicSetting = {
      [TradeDirection.BUY]: {
        label: 'symbol_22',
        themeClass: 'success',
        labelDefault: 'BUY',
      },
      [TradeDirection.SELL]: {
        label: 'symbol_23',
        themeClass: 'danger',
        labelDefault: 'SELL',
      },
    }

    const themeClass = dynamicSetting[type].themeClass

    provide('themeClass', themeClass)
    provide('model', shallowReadonly(model))

    return {
      type,
      symbol,
      isCommission,
      model,
      title: dynamicSetting[type],
      themeClass,
      contractCode,
      sepcialType,
      ESpecialType,
    }
  },
})
</script>
<style lang="scss" scoped>
::v-deep(.page__header) {
  position: fixed !important;
  z-index: 1;
}

.create-bottom {
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
}

.create-body {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;

  .create-content {
    padding-bottom: 48px;
  }
}
</style>
