
import Button from '@/components/Button.vue'
import Line from '@/components/Line.vue'
import QuoteBar from '@/components/QuoteBar.vue'
import Tab from '@/components/Tab.vue'
import { useQuote } from '@/modules/market/market'
import TheCommissionCreateButton from '@/modules/trade/components/TheCommissionCreateButton.vue'
import TheTradeCost from '@/modules/trade/components/TheTradeCost.vue'
import TheStopLoss from '@/modules/trade/components/TheStopLoss.vue'
import TheTakeProfit from '@/modules/trade/components/TheTakeProfit.vue'
import TheTradeLimits from '@/modules/trade/components/TheTradeLimits.vue'
import TheTradePaymentMethods from '@/modules/trade/components/TheTradePaymentMethods.vue'
import ThePositionCreateButton from '@/modules/trade/components/ThePositionCreateButton.vue'
import TheTradeProducts from '@/modules/trade/components/TheTradeProducts.vue'
import TheTradeTargetPrice from '@/modules/trade/components/TheTradeTargetPrice.vue'
import TradeModel from '@/modules/trade/TradeModel'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { TradeDirection } from '@/types'
import DemoAccountTip from '@/views/DemoAccountTip.vue'
import {
  defineComponent,
  provide,
  shallowReadonly,
  shallowRef,
  watchEffect,
} from 'vue'
import { useRoute } from 'vue-router'
import Icon from '@/components/Icon.vue'
import { checkIsSpecial, ESpecialType } from '@/modules/market/market.api'

export default defineComponent({
  name: 'Create',
  components: {
    QuoteBar,
    DemoAccountTip,
    Button,
    TheCommissionCreateButton,
    ThePositionCreateButton,
    TheTradeLimits,
    Line,
    TheTradeCost,
    TheStopLoss,
    TheTakeProfit,
    TheTradePaymentMethods,
    TheTradeTargetPrice,
    TheTradeProducts,
    Tab,
    PageWithHeader,
    Icon,
  },
  setup () {
    const isCommission = shallowRef(false)
    const route = useRoute()
    const type = Number(route.params.type) as TradeDirection
    const model = new TradeModel(type)
    const symbol = useQuote(route.params.code as string)
    const contractCode = route.params.code as string
    const sepcialType = shallowRef(ESpecialType.NORMAL)

    checkIsSpecial({
      contract: route.params.code as string,
    }).then(res => {
      sepcialType.value = res.specialIdentification
    })

    watchEffect(() => {
      if (symbol.value) {
        model.setOffset(symbol.value.sell)
      }
    })

    const dynamicSetting = {
      [TradeDirection.BUY]: {
        label: 'symbol_22',
        themeClass: 'success',
        labelDefault: 'BUY',
      },
      [TradeDirection.SELL]: {
        label: 'symbol_23',
        themeClass: 'danger',
        labelDefault: 'SELL',
      },
    }

    const themeClass = dynamicSetting[type].themeClass

    provide('themeClass', themeClass)
    provide('model', shallowReadonly(model))

    return {
      type,
      symbol,
      isCommission,
      model,
      title: dynamicSetting[type],
      themeClass,
      contractCode,
      sepcialType,
      ESpecialType,
    }
  },
})
