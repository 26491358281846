/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/14
* @description
*   TheCreateCommissionButton.vue of WeTrade
*/
<template>
  <Button class="block" @click="create" :progress="progress">
    <t path="placeorder_19" #="{td}" custom>{{ td || 'Place order' }}</t>
  </Button>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import { createCommissionOrder } from '@/modules/trade/trade'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheCommissionCreateButton',
  components: { Button },
  setup () {
    const [create, progress] = createCommissionOrder()
    return {
      create,
      progress,
    }
  },
})
</script>
