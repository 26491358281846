
import Button from '@/components/Button.vue'
import { createPositionOrder } from '@/modules/trade/trade'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePositionCreateButton',
  components: { Button },
  setup () {
    const [create, progress] = createPositionOrder()
    return {
      create,
      progress,
    }
  },
})
